import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <h1>Easy File</h1>
      <h2>Coming soon...</h2>
      </header>
    </div>
  );
}
export default App;